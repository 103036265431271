.footer-title {
    font-weight: bold;
    color: #7281B0 !important;
    // text-decoration: underline;
}

.dark_blue {

    color: #7281B0 !important;
}

.ft2-icon {
    margin-right: 15px;

    i {
        width: 40px;
        height: 40px;
        background: #104cba;
        border-radius: 100%;
        text-align: center;
        line-height: 40px;
        color: #fff;
    }
}

.ft2-content {
    vertical-align: middle;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;

    label {
        color: #bdc2cb;
        margin: 0;
        display: block;
        font-size: 16px;
        line-height: 28px;
        font-weight: normal;
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

// @-webkit-keyframes pulse {
//     from {
//         -webkit-transform: scale3d(1, 1, 1);
//         transform: scale3d(1, 1, 1)
//     }

//     50% {
//         -webkit-transform: scale3d(1.05, 1.05, 1.05);
//         transform: scale3d(1.05, 1.05, 1.05)
//     }

//     to {
//         -webkit-transform: scale3d(1, 1, 1);
//         transform: scale3d(1, 1, 1)
//     }
// }

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #1A3059;
    /* Semi-transparent dark background */
    z-index: 9999;
    /* Ensure it overlays everything */
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 5px solid rgba(255, 255, 255, 0.3);
    /* Light outer border */
    border-top: 5px solid white;
    /* Solid white color for animation */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}